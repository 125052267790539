import { computed } from 'vue'
import { useCustomerStore } from '@/stores/customer'
import { useSalesStore } from '@/stores/sales'
import { useHpPartnerLogic } from '@/use/hpPartnerLogic'

export function useSalesEligibility() {
  const customerStore = useCustomerStore()
  const salesStore = useSalesStore()
  const hpPartnerLogic = useHpPartnerLogic()

  const productNameConverter = salesStore.getProductNameConverter()
  const overlappingProducts = salesStore.getOverlappingProducts()

  const productUpgrades = computed(() => salesStore.getProductUpgrades || [])
  const products = computed(() => salesStore.getProducts || [])
  const bundles = computed(() => salesStore.getBundles || [])
  const bundlesCount = computed(() => bundles.value.length)
  const subscriptions = computed(() => customerStore.getSubscriptions || [])
  const activeSubsDict = computed(() => {
    const subsDict = {}
    for (const sub of subscriptions.value) {
      if (sub.status.startsWith('Active')) subsDict[sub.product_category] = sub
    }
    return subsDict
  })
  const subsDict = computed(() => {
    const subsDict = {}
    for (const sub of subscriptions.value) subsDict[sub.product_category] = sub
    return subsDict
  })
  const saleProducts = computed(() => {
    const final = []
    salesStore.getProducts?.forEach(p => p.transaction_type == 'Purchase' && !final.includes(p) ? final.push(p) : null)
    return final
  })
  const renewalProducts = computed(() => {
    const final = []
    salesStore.getProducts?.forEach(p => p.transaction_type.includes('Renew') && !final.includes(p) ? final.push(p) : null)
    return final
  })
  const upgradeProducts = computed(() => {
    const final = []
    salesStore.getProducts?.forEach(p => p.transaction_type.includes('Upgrade') && !final.includes(p) ? final.push(p) : null)
    return final
  })
  const straightUpgradeProducts = computed(() => {
    const final = []
    upgradeProducts.value.forEach(p => p.transaction_type == 'Upgrade' && !final.includes(p) ? final.push(p) : null)
    return final
  })
  const renewalUpgradeProducts = computed(() => {
    const final = []
    renewalProducts.value.forEach(p => p.transaction_type.includes('Upgrade') && !final.includes(p) ? final.push(p) : null)
    return final
  })
  const bundleProducts = computed(() => {
    const final = []
    salesStore.getBundles?.forEach(b => {
      for (const p of b.products) {
        if (!final.includes(p.product_category)) final.push(p.product_category)
      }
    })
    return final
  })
  const productBundles = computed(() => {
    const final = {}
    salesStore.getBundles?.forEach(b => {
      for (const p of b.products) {
        if (!final[p.product_category]) final[p.product_category] = []
        final[p.product_category].push(b)
      }
    })
    return final
  })

  const productInCart = computed(() => salesStore.getCartList.some(item => item.transaction_type != 'Bundle'))

  const bundleInCart = computed(() => salesStore.getCartList.some(item => item.transaction_type == 'Bundle'))

  const getProductShortName = product => {
    if (product == 'None') return
    let short = productNameConverter[product]
    if (!short) {
      if (hasRenewalUpgrade(product) || hasProductUpgrade(product)) {
        const j = productUpgrades.value.find(p => p.from_product = product)
        if (!j) return null
        short = productNameConverter[j.to_product] 
      }
      // if (!short) console.error(`${product} does not have acronym assigned for sales`)
    }
    return short
  }

  // Determines which products and terms are available for sale based on the customer's existing subscription portfolio. 
  const eligibleOffers = computed(() => {
    const final = {} // Use a dictionary for direct reference
    if (!overlappingProducts) return final // not loaded yet
    const activeSubs = activeSubsDict.value
    
    // Eligible Sales
    for (const saleProduct of saleProducts.value) {
      // Itterate products for sale and see if they already own it or a similar "overlapping" product
      // If not, make the product available for purchase
      const product = saleProduct.product_category
      let sub = activeSubs[product]
      if (!sub) {
        if (!ownsAROverlap(product)) {
          if (!productNameConverter[product]) continue
          final[`${productNameConverter[product]}_PURCH_ELIG`] = true // Sale if no active product or overlap
        }
      }
    }
    
    // Eligible Renewals
    for (const renewProduct of renewalProducts.value) {
      // For each product with a Renew or Renew+Upgrade transaction option
      // compile a list of active subscriptions of those products, and their overlapping products
      const allProductSubs = []
      const product = renewProduct.product_category
      let sub = activeSubs[product]
      if (sub) allProductSubs.push(sub)
      if (overlappingProducts[product]) {
        for (const alt in overlappingProducts[product]) {
          if (activeSubs[alt]) {
            allProductSubs.push(activeSubs[alt])
          }
        }
      }
      // Calculate renewal eligibility based on the product's active subscriptions
      const allProductSubsCount = allProductSubs.length
      if ((
            allProductSubsCount == 1
            || (allProductSubsCount > 1 && allProductSubs.filter(s => s.status == 'Active - AR On').length == 1)
          )
          && !allProductSubs.find(s => s.status == 'Active - Dunning')
      ) {
        sub = allProductSubsCount == 1 ? allProductSubs[0] : allProductSubs.find(s => s.status == 'Active - AR On') // Make sure that we renew the correct active subscription in case there are multiple
        if (sub.last_is_prepaid) continue
        const short = getProductShortName(sub.product_category)
        if (!short) continue // If there is no acronym for the product then it is not an actively sold product
        // Check if the product has these transaction types available
        const renew = sub.billing_model != 'Once' && hasRenewal(product) // !!(sub.days_until_renewal <= 60) && 
        const renewUpgrade = hasRenewalUpgrade(product)
        const productUpgrade = hasProductUpgrade(product)
        final[`${short}_RENEW_ELIG`] = renew
        final[`${short}_RENEW_CANCELLED`] = renew && sub.status == 'Active - AR Off'
        if (product == 'LiveTech') final[`${short}_RENEW_DW`] = renew && sub.product_category.includes('Dark Web')
        final[`${short}_RENEW_UPGRADE_ELIG`] = renewUpgrade
        final[`${short}_UPGRADE_ELIG`] = productUpgrade
        final[`${short}_ORDER_ID`] = sub.order_id
        final[`${short}_PRODUCT_ID`] = sub.product_id
      } else if (allProductSubsCount > 1) {
        console.log(`${product} Not Eligible for Renew`)
      }
    }
    console.log(`Eligible offers`, final)
    return final
  })

  const eligibleRecommendedOffers = computed(() => {
    const final = {}
    const elig = eligibleOffers.value
    for (const k in elig) {
      if (k.includes('UPGRADE')) continue
      if (!k.includes('RENEW') && !k.includes('PURCH')) continue
      final[k] = elig[k]
    }
    console.log(`Eligible Recommended offers`, final)
    return final
  })

  const isSaleEligible = sub => {
    let short = productNameConverter[sub.product_category]
    if (short && eligibleOffers.value[`${short}_PURCH_ELIG`]) return true
    if (!short) short = getProductShortName(sub.product_category)
    if (!short) {
      if (sub.product_line_category == 'Tech Support' && sub.status.startsWith('Active')) short = 'LT'
      else return false
    }
    if ((eligibleOffers.value[`${short}_RENEW_ELIG`] || eligibleOffers.value[`${short}_RENEW_UPGRADE_ELIG`])
        && (eligibleOffers.value[`${short}_ORDER_ID`] != sub.order_id || eligibleOffers.value[`${short}_PRODUCT_ID`] != sub.product_id)
      ) return false
    return Object.keys(eligibleOffers.value).some(o => o.startsWith(short) && eligibleOffers.value[o] == true && o.endsWith('_ELIG'))
  }

  const ownsOverlap = product_category => {
    const over = overlappingProducts[product_category]
    if (!over) return false
    let hasOverlap = false
    for (const alt of over) {
      if (activeSubsDict.value[alt]) {
        hasOverlap = true
        break
      }
    }
    return hasOverlap
  }

  const ownsAROverlap = product_category => {
    const over = overlappingProducts[product_category]
    if (!over) return false
    let hasOverlap = false
    for (const alt of over) {
      if (activeSubsDict.value[alt] && ['Active - AR On','Active - Dunning'].includes(activeSubsDict.value[alt].status)) {
        hasOverlap = true
        break
      }
    }
    return hasOverlap
  }

  const hasOverlap = product_category => {
    const over = overlappingProducts[product_category]
    if (!over) return false
    let hasOverlap = false
    for (const alt of over) {
      if (subsDict.value[alt]) {
        hasOverlap = true
        break
      }
    }
    return hasOverlap
  }

  const hasRenewal = product_category => !!renewalProducts.value.find(p => p.product_category == product_category && p.transaction_type == 'Renew')

  const hasProductUpgrade = product_category => !!upgradeProducts.value.find(p => p.product_category == product_category)

  const hasRenewalUpgrade = product_category => !!renewalUpgradeProducts.value.find(p => p.product_category == product_category)

  const renewalSub = sub => {
    const short = getProductShortName(sub.product_category)
    if (eligibleOffers.value[`${short}_RENEW_ELIG`] && eligibleOffers.value[`${short}_ORDER_ID`] == sub.order_id && eligibleOffers.value[`${short}_PRODUCT_ID`] == sub.product_id) return true
    return false
  }

  const getSalesProductCategory = sub => sub.transaction_type == 'Renew' && sub.product_line_category == 'Tech Support' && sub.product_category != 'LiveTech' ? 'LiveTech' : sub.product_category

  const getTransactionOptions = sub => {
    let final = []
    if (salesStore.getPromoCode) return final
    let short = getProductShortName(sub.product_category)
    if (!short) {
      if (overlappingProducts.LiveTech.includes(sub.product_category)) short = 'LT'
      else return final
    }
    if (!bundleInCart.value || sub.transaction_type == 'Bundle') {
      if (eligibleOffers.value[`${short}_PURCH_ELIG`] && (['Former','Never'].includes(sub.status))) {
        if (Object.keys(salesStore.getCart).length) {
          const duplicate = Object.values(salesStore.getCart).some(item => item.product_category == sub.product_category)
          if (!duplicate) final.push('Purchase')
        } else {
          final.push('Purchase')
        }
        if (sub.transaction_type == 'Bundle' || (!productInCart.value && final[0] == 'Purchase' && bundleProducts.value?.includes(sub.product_category))) final.push('Bundle')
      }
      if (eligibleOffers.value[`${short}_RENEW_ELIG`] && eligibleOffers.value[`${short}_ORDER_ID`] == sub.order_id && eligibleOffers.value[`${short}_PRODUCT_ID`] == sub.product_id) final.push('Renew')
      if (!hpPartnerLogic.isHpCustomer.value && eligibleOffers.value[`${short}_RENEW_UPGRADE_ELIG`] && eligibleOffers.value[`${short}_ORDER_ID`] == sub.order_id && eligibleOffers.value[`${short}_PRODUCT_ID`] == sub.product_id) final.push('Upgrade+Renew')
    }
    return final
  }
  
  const getProductUpgradeOptions = sub => {
    const final = []
    if (!sub.transaction_type.includes('Upgrade')) return final
    const options = upgradeProducts.value.filter(p => p.product_category == sub.product_category)
    if (options.length == 0) return options
    if (sub.transaction_type?.includes('Upgrade') || (!sub.transaction_type && getTransactionOptions(sub).includes('Upgrade'))) {
      const sizes = sub.product_size ? [sub.product_size] : getSizeOptions(sub)
      const terms = sub.initial_term ? [sub.initial_term] : getTermOptions(sub)
      options.forEach(p => {
        if (sizes.includes(p.product_size) && terms.includes(p.initial_term) && !final.includes(p.offer_product)) final.push(p.offer_product)
      })
    }
    return final
  }

  const getTermOptions = sub => {
    const terms = []
    if (!sub.transaction_type && !sub.product_size) return terms
    let pc = getSalesProductCategory(sub)
    if (sub.transaction_type == 'Bundle') {
      productBundles.value[pc].forEach(b => {
        const pCount = b.products.length
        for (let i = 0; i < pCount; i++) {
          const p = b.products[i]
          if (p.offer_product == pc
              && (!sub.product_size || p.product_size == sub.product_size)
              && !terms.includes(p.initial_term)
          ) terms.push(p.initial_term)
        }
      })
      return terms   
    }
    const bt = sub.billing_model == 'Yearly' ? '1 Year' : sub.billing_model
    products.value.forEach(o => {
      if (o.transaction_type == sub.transaction_type
          && o.product_category == pc
          && (!sub.product_size || o.product_size == sub.product_size)
          && (sub.transaction_type != 'Renew' || o.initial_term == bt)
          // && (!sub.transaction_type.includes('Upgrade') || pc != o.offer_product || (pc == o.offer_product && o.initial_term != sub.billing_model))
          && !terms.includes(o.initial_term)) {
        terms.push(o.initial_term)
      }
    })
    return terms
  }

  const isValidSize = (o, sub) => {
    if (sub.product_line == 'IDProtect' && sub.product_size == 'Family' && o.product_size == 'Individual') return false // No IDProtect downgrades
    return true // Allow downgrades for all others
  }

  const getSizeOptions = sub => {
    const sizes = []
    if (!sub.transaction_type) return sizes
    let pc = getSalesProductCategory(sub)
    if (sub.transaction_type == 'Bundle') {
      productBundles.value[pc].forEach(b => {
        const pCount = b.products.length
        for (let i = 0; i < pCount; i++) {
          const p = b.products[i]
          if (p.offer_product == pc
              && (!sub.initial_term || p.initial_term == sub.billing_model)
              && !sizes.includes(p.product_size)
          ) sizes.push(p.product_size)
        }
      })
      return sizes   
    }
    products.value.forEach(o => {
      if (o.transaction_type == sub.transaction_type
          && o.product_category == pc
          && (!sub.billing_model || o.initial_term == sub.billing_model)
          && (sub.transaction_type != 'Renew' || o.product_size == sub.sku_size)
          // && (!sub.transaction_type.includes('Upgrade') || pc != o.offer_product || (pc == o.offer_product && o.product_size != sub.sku_size))
          && !sizes.includes(o.product_size)
          && isValidSize(o, sub)) {
        sizes.push(o.product_size)
      }
    })
    return sizes
  }

  const getBundleProductOptions = sub => {
    const final = []
    if (sub.transaction_type != 'Bundle') return final
    const bundles = productBundles.value[sub.product_category]
    if (bundles) {
      bundles.forEach(b => {
        const prod = b.products.find(p => p.product_category == sub.product_category)
        if (sub.initial_term == prod.initial_term && sub.product_size == prod.product_size) {
          const pCount = b.products.length
          for (let i = 0; i < pCount; i++) {
            const p = b.products[i]
            if (p.product_category != sub.product_category && !final.includes(p.product_category)) {
              final.push(p.product_category)
            }
          }
        }
      })
    }
    return final
  }

  const getBundleSizeOptions = sub => {
    const final = []
    if (sub.transaction_type != 'Bundle' || !sub.bundle_product) return final
    let pc = getSalesProductCategory(sub)
    const bCount = productBundles.value[pc].length
    for (let a = 0; a < bCount; a++) {
      const b = productBundles.value[pc][a]
      if (b.products.some(p => p.offer_product == pc && p.initial_term == sub.initial_term && p.product_size == sub.product_size)) {
        const pCount = b.products.length
        for (let i = 0; i < pCount; i++) {
          const p = b.products[i]
          if (p.offer_product != pc && sub.bundle_product == p.product_category && (!sub.bundle_term || sub.bundle_term == p.initial_term) && !final.includes(p.product_size)) final.push(p.product_size)
        }
      }
    }
    return final
  }

  const getBundleTermOptions = sub => {
    const final = []
    if (sub.transaction_type != 'Bundle' || !sub.bundle_product || !sub.bundle_size) return final
    let pc = getSalesProductCategory(sub)
    const bCount = productBundles.value[pc].length
    for (let a = 0; a < bCount; a++) {
      const b = productBundles.value[pc][a]
      if (b.products.some(p => p.offer_product == pc && p.initial_term == sub.initial_term && p.product_size == sub.product_size)) {
        const pCount = b.products.length
        for (let i = 0; i < pCount; i++) {
          const p = b.products[i]
          if (p.offer_product != pc && sub.bundle_product == p.product_category && sub.bundle_size == p.product_size && !final.includes(p.initial_term)) final.push(p.initial_term)
        }
      }
    }
    return final
  }

  const getPromoOptions = computed(() => {
    const codes = []
    const cartCount = salesStore.getCartCount
    if (!cartCount) return codes
    if (cartCount > 1) {
      const cartIds = salesStore.getCartList.map(p => p.offer_configuration_id)
      for (let b = 0; b < bundlesCount.value; b++) {
        const bundle = bundles.value[b]
        const prodCount = bundle.products.length
        if (prodCount != cartCount) continue
        const ids = [...cartIds]
        let index = null
        for (let i = 0; i < prodCount; i++) {
          // console.log(ids, bundle.products[i].offer_configuration_id)
          index = ids.indexOf(bundle.products[i].offer_configuration_id)
          if (index >= 0) ids.splice(index, 1)
          else break
        }
        if (index >= 0 && !ids.length) codes.push(bundle.promo_code)
      }
    } else {
      for (let i = 0; i < cartCount; i++) {
        let c = salesStore.getCartList[i]
        products.value.forEach(product => {
          if (product.offer_configuration_id == c.offer_configuration_id
            && product.transaction_type == c.transaction_type
            && !codes.includes(product.promo_code)
          ) {
            codes.push(product.promo_code)
          }
        })
      }
    }
    if (codes.length == 1 && codes[0] == null) return []
    return codes
  })

  return {
    eligibleOffers, eligibleRecommendedOffers, isSaleEligible, saleProducts, renewalProducts, renewalUpgradeProducts, straightUpgradeProducts,
    bundleProducts, productBundles, getBundleProductOptions, getBundleTermOptions, getBundleSizeOptions, getSalesProductCategory,
    getTransactionOptions, getTermOptions, getSizeOptions, getPromoOptions, getProductUpgradeOptions, ownsOverlap, ownsAROverlap, hasOverlap, hasRenewal, hasRenewalUpgrade, hasProductUpgrade,
    bundleInCart, activeSubsDict, renewalSub
  }
}